import React, { useState, useEffect } from "react";
import axios from "axios";

const TotalDocumentsComponent = () => {
  const apiUrl = "https://ysr-backend-1.onrender.com";
  const [success, setSuccess] = useState(false);
  const [totalDocuments, setTotalDocuments] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTotalDocuments = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/api/v1/user/admin/total-doc-submitted`
        );
        const responseData = response.data;
        setSuccess(responseData.success);
        setTotalDocuments(responseData.data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchTotalDocuments();
  }, []);

  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        <div>
          {success && (
            <p>
              Total documents submitted: <br />
              {totalDocuments}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default TotalDocumentsComponent;
