import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
  Paper,
  Alert,
  Box,
} from "@mui/material";
import CenteredCircularProgress from "../../helper/Loading";
import api from "../../api";

const StudentDocumentsDetails = () => {
  const { id } = useParams();
  const [documents, setDocuments] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await api.getStudentDocumentsDetails(id);
        setDocuments(response.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchDocuments();
  }, [id]);

  if (loading) {
    return <CenteredCircularProgress />;
  }

  if (error) {
    return (
      <Container>
        <Box mt={4}>
          <Alert severity="error">Error: {error}</Alert>
        </Box>
      </Container>
    );
  }

  if (!documents || Object.keys(documents).length === 0) {
    return (
      <Container>
        <Box mt={4}>
          <Alert severity="info">No documents submitted till now.</Alert>
        </Box>
      </Container>
    );
  }

  const documentFields = [
    { label: "SSC Long Memo", field: "sscLongMemo" },
    { label: "SSC Short Memo", field: "sscShortMemo" },
    { label: "Inter Long Memo", field: "interLongMemo" },
    { label: "Inter Short Memo", field: "interShortMemo" },
    { label: "SSC Bonafide Certificate", field: "SSCBonafideCertificate" },
    { label: "Inter Bonafide Certificate", field: "HSCBonafideCertificate" },
    { label: "Cast Certificate", field: "castCertificate" },
    { label: "Income Certificate", field: "incomeCertificate" },
    { label: "EAPCET Hall Ticket", field: "EAPCETHallTicket" },
    { label: "EAPCET Rank Card", field: "EAPCETRankCard" },
    { label: "Inter TC", field: "interTC" },
    { label: "ROC Certificate", field: "ROC" },
    { label: "Adhaar Card Xerox", field: "adhaarCardXerox" },
  ];

  return (
    <Container>
      <Box mt={4}>
        <Typography variant="h4" gutterBottom>
          Student Documents Details
        </Typography>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Grid container spacing={3}>
            {documentFields.map(({ label, field }) => (
              <Grid item xs={12} sm={6} md={4} key={field}>
                <Typography variant="h6" gutterBottom>
                  {label}
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={documents[field] === "ORIGINAL"}
                      readOnly
                    />
                  }
                  label="Original"
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={documents[field] === "XEROX"} readOnly />
                  }
                  label="Xerox"
                />
              </Grid>
            ))}
          </Grid>
        </Paper>
      </Box>
    </Container>
  );
};

export default StudentDocumentsDetails;
