import React, { useState } from "react";
import "./Summary.css";

const EapcetDet = () => {
  return (
    <div className="summary">
      <div className="card pink">
        <span className="title">$1k</span>
        <span className="subtitle">Total Walkins</span>
        <span className="change">+8% from yesterday</span>
      </div>
      <div className="card green">
        <span className="title"></span>
        <span className="subtitle">Management</span>
        <span className="change">+1.2% from yesterday</span>
      </div>
      <div className="card yellow">
        <span className="title">300</span>
        <span className="subtitle">Docs Collected</span>
        <span className="change">+5% from yesterday</span>
      </div>
      <div className="card purple">
        <span className="title">8</span>
        <span className="subtitle">Fees Collected</span>
        <span className="change">0.5% from yesterday</span>
      </div>
      <div className="card light-blue">
        <span className="title">1</span>
        <span className="subtitle">Returned Docs</span>
        <span className="change">0.5% from yesterday</span>
      </div>
    </div>
  );
};

export default EapcetDet;
