import React from "react";
import { Grid, CircularProgress } from "@mui/material";

const CenteredCircularProgress = () => (
  <Grid
    container
    justifyContent="center"
    alignItems="center"
    style={{ minHeight: "100vh" }}
  >
    <Grid item>
      <CircularProgress />
    </Grid>
  </Grid>
);

export default CenteredCircularProgress;
