import AdminHome from "../Components/Admin/AdminHome";
import { HttpService } from "./http.service";

const eapcetFormSubmit = async (formData) => {
  try {
    const response = await HttpService.post(
      `/api/v1/user/create-student-registration`,
      formData
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const ecetFormSubmit = async (formData) => {
  try {
    const response = await HttpService.post(
      `/api/v1/user/ecet-student-registration`,
      formData
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const addEmployee = async (formData) => {
  try {
    const response = await HttpService.post(
      `/api/v1/user/admin/add-employees`,
      formData
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const removeEmployee = async (id) => {
  try {
    const response = await HttpService.delete(
      `/api/v1/user/admin/remove-employee-by-id/${id}`
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const getAllEmployeesList = async () => {
  try {
    const response = await HttpService.get(
      `/api/v1/user/admin/get-all-employees`
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};
const getAllEmployees = async (formData) => {
  try {
    const response = await HttpService.get(
      `/api/v1/user/admin/get-all-employees`,
      formData
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const getStudentsDetailsById = async (studentId) => {
  try {
    const response = await HttpService.get(
      `/api/v1/admin/get-student-details-by-id/${studentId}`
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const updateEmployee = async (id, name, phoneNumber) => {
  try {
    const response = await HttpService.put(
      `/api/v1/user/admin/update-employee-by-id`,
      { id, name, phoneNumber }
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};
const getTotalConfirmation = async () => {
  try {
    const response = await HttpService.get(
      `/api/v1/user/admin/total-doc-submitted`
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};
const getEapcetStudentsList = async () => {
  try {
    const response = await HttpService.get(
      "/api/v1/user/admin/allType-students-data/EAPCET"
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const addEapcetDocuments = async (formData) => {
  try {
    const response = await HttpService.post(
      "/api/v1/user/admin/add-student-documents",
      formData
    );
    return response;
  } catch (error) {
    throw error.response.data;
  }
};
const getStudentDocumentsDetails = async (id) => {
  try {
    const response = await HttpService.get(
      `/api/v1/user/admin/student-documents-by-student-id/${id}`
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

const adminLogin = async (id) => {
  try {
    const response = await HttpService.post(`/api/v1/admin/user-login`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};
const updateStudentDetails = async (studentId, formData) => {
  try {
    const response = await HttpService.put(
      `/api/v1/user/admin/update-student/${studentId}`,
      formData
    );
    console.log(9999999999999999);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export default {
  eapcetFormSubmit,
  ecetFormSubmit,
  getAllEmployees,
  getStudentsDetailsById,
  addEmployee,
  removeEmployee,
  updateEmployee,
  getAllEmployeesList,
  getTotalConfirmation,
  getEapcetStudentsList,
  addEapcetDocuments,
  getStudentDocumentsDetails,
  adminLogin,
  updateStudentDetails,
};
