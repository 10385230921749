import React from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const CustomLoadingButton = ({ loading, children, ...props }) => {
  return (
    <Button {...props} disabled={loading}>
      {loading && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)"
          }}
        >
          <CircularProgress size={24} />
        </Box>
      )}
      <span style={{ visibility: loading ? "hidden" : "visible" }}>
        {children}
      </span>
    </Button>
  );
};

export default CustomLoadingButton;
