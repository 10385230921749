import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";

const AddEmployeeForm = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "phoneNumber" && value.length > 10) {
      return;
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage("");

    try {
      if (formData.phoneNumber.length !== 10) {
        throw new Error("Phone number must be 10 digits long");
      }

      const response = await api.addEmployee(formData);

      console.log("API Response:", response);

      if (response.success === true) {
        setMessage(response.message);
        navigate("/employee-response");
      } else {
        console.log("Unexpected API Response:", response);
        const errorData = response.data || {};
        setMessage(errorData.message || "Unknown error");
      }
    } catch (error) {
      console.error("API Request Failed:", error);
      setMessage("Phone number must be 10 digits long");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Box>
        <Typography variant="h4" gutterBottom>
          Add Employee
        </Typography>
        {loading ? (
          <Box>
            <CircularProgress />
          </Box>
        ) : (
          <form onSubmit={handleSubmit}>
            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                id="name"
                label="Name"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                variant="outlined"
                helperText="Please enter the name of the employee"
              />
            </Box>

            <Box>
              <TextField
                fullWidth
                id="phoneNumber"
                label="Phone Number"
                type="text"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
                variant="outlined"
                error={formData.phoneNumber.length !== 10}
                helperText={
                  formData.phoneNumber.length !== 10
                    ? "Phone number must be exactly 10 digits long"
                    : ""
                }
              />
            </Box>

            <Box>
              <Button type="submit" variant="contained" color="primary">
                Add Employee
              </Button>
            </Box>
          </form>
        )}
        {message && (
          <Typography
            variant="body1"
            color={message.startsWith("Success") ? "success" : "error"}
          >
            {message}
          </Typography>
        )}
      </Box>
    </Container>
  );
};

export default AddEmployeeForm;
