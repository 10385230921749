import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Container,
  Typography,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Box,
  Paper,
  Divider,
  Grid,
  FormHelperText,
} from "@mui/material";
import axios from "axios";

const AddEapcetDocuments = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    sscLongMemo: "",
    sscShortMemo: "",
    SSCBonafideCertificate: "",
    interLongMemo: "",
    interShortMemo: "",
    interTC: "",
    HSCBonafideCertificate: "",
    adhaarCardXerox: "",
    incomeCertificate: "",
    castCertificate: "",
    ROC: "",
    EAPCETHallTicket: "",
    EAPCETRankCard: "",
    studentId: id,
  });
  const [formError, setFormError] = useState("");

  const handleCheckboxChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: prevData[name] === value ? "" : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const selectedDocuments = Object.values(formData).filter(
      (value) => value !== ""
    );
    if (selectedDocuments.length <= 1) {
      setFormError("Please select at least one document.");
      return;
    }
    setFormError(""); // Clear any existing errors
    try {
      const response = await axios.post(
        "https://ysr-backend-1.onrender.com/api/v1/user/admin/add-student-documents",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.data.success) {
        throw new Error("Failed to add documents");
      }
      alert("Documents details added successfully!");
      navigate(`/allType-students-list`);
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };

  const documents = [
    { label: "SSC Long Memo", name: "sscLongMemo" },
    { label: "SSC Short Memo", name: "sscShortMemo" },
    { label: "Inter Long Memo", name: "interLongMemo" },
    { label: "Inter Short Memo", name: "interShortMemo" },
    { label: "SSC Bonafide Certificate", name: "SSCBonafideCertificate" },
    { label: "Inter Bonafide Certificate", name: "HSCBonafideCertificate" },
    { label: "Cast Certificate", name: "castCertificate" },
    { label: "Income Certificate", name: "incomeCertificate" },
    { label: "EAPCET Hall Ticket", name: "EAPCETHallTicket" },
    { label: "EAPCET Rank Card", name: "EAPCETRankCard" },
    { label: "Inter TC", name: "interTC" },
    { label: "ROC Certificate", name: "ROC" },
    { label: "Adhaar Card Xerox", name: "adhaarCardXerox" },
  ];

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} style={{ padding: "2rem", marginTop: "2rem" }}>
        <Typography variant="h4" gutterBottom align="center">
          Document Details
        </Typography>
        <Typography variant="h6" gutterBottom align="center">
          Student ID: {id}
        </Typography>
        <Divider />
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} mt={2}>
            {documents.map((doc) => (
              <Grid item xs={12} sm={6} key={doc.name}>
                <FormControl component="fieldset" fullWidth variant="standard">
                  <FormLabel component="legend">{doc.label}</FormLabel>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={doc.name}
                          value="ORIGINAL"
                          checked={formData[doc.name] === "ORIGINAL"}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="Original"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={doc.name}
                          value="XEROX"
                          checked={formData[doc.name] === "XEROX"}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="Xerox"
                    />
                  </FormGroup>
                  <FormHelperText>Select Original or Xerox</FormHelperText>
                </FormControl>
              </Grid>
            ))}
          </Grid>
          {formError && (
            <Box mt={2}>
              <Typography color="error">{formError}</Typography>
            </Box>
          )}
          <Box mt={3} display="flex" justifyContent="center">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
            >
              Submit
            </Button>
          </Box>
        </form>
      </Paper>
    </Container>
  );
};

export default AddEapcetDocuments;
