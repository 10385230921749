import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import {
  Container,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  CircularProgress,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import VisibilityIcon from "@mui/icons-material/Visibility";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import SearchIcon from "@mui/icons-material/Search"; // Correct import statement
import EapcetUpdateForm from "./EapcetUpdateForm";
import CenteredCircularProgress from "../../helper/Loading";

const NoWrapTableCell = styled(TableCell)`
  white-space: nowrap;
`;
const BASE_URL = "https://ysr-backend-1.onrender.com/api/v1/user/admin";

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const deleteStudent = async (studentId) => {
  try {
    const response = await api.delete(
      `${BASE_URL}/delete-student/${studentId}`
    );
    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to delete student"
    );
  }
};

const updateStudent = async (studentId, updatedData) => {
  try {
    const response = await api.put(
      `${BASE_URL}/update-student/${studentId}`,
      updatedData
    );
    return response.data;
  } catch (error) {
    throw new Error(
      error.response?.data?.message || "Failed to update student"
    );
  }
};

const StudentList = () => {
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("EAPCET");
  const navigate = useNavigate();
  const [deleting, setDeleting] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [currentStudentId, setCurrentStudentId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchError, setSearchError] = useState(null);

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${BASE_URL}/allType-students-data/${selectedCategory}`
        );
        setStudents(response.data.data);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchStudents();
  }, [selectedCategory]);

  const handleSelectChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleViewClick = (studentId) => {
    navigate(`/get-student-details-by-id/${studentId}`);
  };

  const handleAddDocumentsClick = (studentId, requestType) => {
    if (requestType.toUpperCase() === "EAPCET") {
      navigate(`/add-certificate-details/${studentId}`);
    }
  };

  const handleDelete = async () => {
    if (currentStudentId) {
      try {
        const response = await deleteStudent(currentStudentId);
        if (response.success) {
          setStudents((prevStudents) =>
            prevStudents.filter((student) => student.id !== currentStudentId)
          );
        } else {
          throw new Error(response.message || "Failed to delete student");
        }
      } catch (error) {
        throw new Error(error.message || "Failed to delete student");
      } finally {
        setCurrentStudentId(null);
        setOpenDeleteDialog(false);
      }
    }
  };

  const handleUpdateClick = (studentId) => {
    setCurrentStudentId(studentId);
    setOpenUpdateDialog(true);
  };

  const handleConfirmUpdate = async (updatedData) => {
    if (currentStudentId) {
      try {
        const response = await updateStudent(currentStudentId, updatedData);
        if (response.success) {
          setStudents((prevStudents) =>
            prevStudents.map((student) =>
              student.id === currentStudentId
                ? { ...student, ...updatedData }
                : student
            )
          );
          navigate(`/allType-students-list`);
        } else {
          throw new Error(response.message || "Failed to update student");
        }
      } catch (error) {
        throw new Error(error.message || "Failed to update student");
      } finally {
        setCurrentStudentId(null);
        setOpenUpdateDialog(false);
      }
    }
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setCurrentStudentId(null);
  };

  const handleCloseUpdateDialog = () => {
    setOpenUpdateDialog(false);
    setCurrentStudentId(null);
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearch = async () => {
    try {
      setSearchLoading(true);
      const response = await axios.get(
        `${BASE_URL}/search-student/${searchTerm}`
      );

      setSearchResults(response.data.data);
    } catch (error) {
      setSearchError(error.message || "Failed to search");
    } finally {
      setSearchLoading(false);
    }
  };

  if (loading) return <CenteredCircularProgress />;
  if (error)
    return <Typography color="error">Error: {error.message}</Typography>;

  const currentStudents = searchResults.length > 0 ? searchResults : students;

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Student Details
      </Typography>
      <FormControl
        fullWidth
        variant="outlined"
        margin="normal"
        style={{ width: "25%" }}
      >
        <InputLabel id="categorySelectLabel">Select Category</InputLabel>
        <Select
          labelId="categorySelectLabel"
          id="categorySelect"
          value={selectedCategory}
          onChange={handleSelectChange}
          label="Select Category"
        >
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="EAPCET">EAPCET</MenuItem>
          <MenuItem value="ECET">ECET</MenuItem>
          <MenuItem value="PGCET">PGCET</MenuItem>
          <MenuItem value="MANAGAMENTS">MANAGAMENTS</MenuItem>
        </Select>
      </FormControl>

      <FormControl
        fullWidth
        variant="outlined"
        margin="normal"
        style={{ marginLeft: "50%", width: "25%", alignItems: "end" }}
      >
        <OutlinedInput
          id="searchInput"
          value={searchTerm}
          onChange={handleInputChange}
          endAdornment={
            <InputAdornment position="end">
              <IconButton onClick={handleSearch} edge="end">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
          placeholder={`Enter search ${selectedCategory}`}
          aria-describedby="search-helper-text"
        />
      </FormControl>

      {searchLoading && <CircularProgress />}

      {searchError && (
        <Typography variant="body1" color="error" gutterBottom>
          Error: {searchError}
        </Typography>
      )}

      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ backgroundColor: "#0D83AD" }}>
            <TableRow>
              <TableCell style={{ color: "#ffffff", textAlign: "center" }}>
                ID
              </TableCell>
              <NoWrapTableCell
                style={{ color: "#ffffff", textAlign: "center" }}
              >
                Date
              </NoWrapTableCell>
              <TableCell style={{ color: "#ffffff", textAlign: "center" }}>
                Name of Applicant
              </TableCell>
              <TableCell style={{ color: "#ffffff", textAlign: "center" }}>
                Father's Name
              </TableCell>
              <TableCell style={{ color: "#ffffff", textAlign: "center" }}>
                Category
              </TableCell>
              <TableCell style={{ color: "#ffffff", textAlign: "center" }}>
                Number
              </TableCell>
              <TableCell style={{ color: "#ffffff", textAlign: "center" }}>
                Reference
              </TableCell>
              <TableCell style={{ color: "#ffffff", textAlign: "center" }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentStudents.map((student) => (
              <TableRow key={student.id}>
                <TableCell>{student.id}</TableCell>
                <NoWrapTableCell>{student.date}</NoWrapTableCell>
                <TableCell>{student.nameOfApplicant}</TableCell>
                <TableCell>{student.fatherName}</TableCell>
                <TableCell>{student.category}</TableCell>
                <TableCell>{student.phoneNumber}</TableCell>
                <TableCell>{student.withReferenceOf}</TableCell>
                <TableCell>
                  <Box display="flex" gap={1}>
                    <IconButton
                      color="primary"
                      onClick={() => handleUpdateClick(student.id)}
                    >
                      <BorderColorIcon />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() => {
                        setCurrentStudentId(student.id);
                        setOpenDeleteDialog(true);
                      }}
                      disabled={deleting === student.id}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <IconButton
                      color="primary"
                      onClick={() => handleViewClick(student.id)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      onClick={() =>
                        handleAddDocumentsClick(student.id, student.requestType)
                      }
                    >
                      <NoteAddIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to delete this student?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openUpdateDialog}
        onClose={handleCloseUpdateDialog}
        aria-labelledby="update-dialog-title"
        aria-describedby="update-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="update-dialog-title">
          Update Student Details
        </DialogTitle>
        <DialogContent>
          <EapcetUpdateForm
            studentId={currentStudentId}
            onSubmit={handleConfirmUpdate}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUpdateDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default StudentList;
