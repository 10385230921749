import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import { CircularProgress, FormControl } from "@mui/material";
import api from "../../api";

const Container = styled.div`
  max-width: auto;
  margin: auto;
  padding: 30px;
  @media (max-width: 600px) {
    padding: 30px;
  }
`;

const Button = styled.button`
  padding: 10px;
  margin-top: 2%;
  background-color: #61dafb;
  border: none;
  cursor: pointer;
  font-size: 16px;
  &:hover {
    background-color: #21a1f1;
  }
`;
const CheckboxGroup = styled.div`
  display: flex;
  margin-left: 5%;
  margin-bottom: 2%;
  margin-top: 2%;
  flex-wrap: wrap;
  gap: 15px;
`;
const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  margin-top: 10px;
  margin-bottom: 10px;
`;
const Select = styled.select`
  padding: 8px;
  font-size: 16px;
  width: 100%;
  border: none;
  box-sizing: border-box;
  background: #fafafa;
`;

const LabelA = styled.label`
  margin-bottom: 1px;
  display: flex;
`;

const Input = styled.input`
  padding: 8px;
  font-size: 16px;
  width: 100%;
  border: none;
  box-sizing: border-box;
  background: #fafafa;
`;

const EapcetUpdateForm = ({ studentId }) => {
  const [employees, setEmployees] = useState([""]);
  const [formData, setFormData] = useState({
    nameOfApplicant: "",
    fatherName: "",
    date: "",
    dateOfBirth: "",
    addressOfCommunication: "",
    phoneNumber: "",
    phoneNumber1: "",
    aadharNo: "",
    category: "",
    courseName: "",
    nameofInstution: "",
    requestType: "",
    withReferenceOf: "",
    reference: [
      {
        friendName: "",
        friendPhoneNumber: "",
      },
    ],
    qualifyingDetails: {
      sscSchoolName: "",
      sscPassingYear: "",
      sscPercentage: "",
      hscSchoolName: "",
      hscPassingYear: "",
      hscPercentage: "",
      EAPCETHallTicketNo: "",
      EAPCETRank: "",
    },
  });

  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleNestedChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      qualifyingDetails: {
        ...formData.qualifyingDetails,
        [name]: value,
      },
    });
  };

  const handleReferenceChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      reference: [
        {
          ...formData.reference[0],
          [name]: value,
        },
      ],
    });
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    const updatedCourseName = checked
      ? [...formData.courseName, value]
      : formData.courseName.filter((level) => level !== value);
    setFormData({
      ...formData,
      courseName: updatedCourseName,
    });
  };
  const fetchStudentDetails = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://ysr-backend-1.onrender.com/api/v1/admin/get-student-details-by-id/${studentId}`
      );
      const studentData = response.data.data;
      setFormData({
        nameOfApplicant: studentData.nameOfApplicant,
        fatherName: studentData.fatherName,
        date: studentData.date,
        dateOfBirth: studentData.dateOfBirth,
        addressOfCommunication: studentData.addressOfCommunication,
        phoneNumber: studentData.phoneNumber,
        phoneNumber1: studentData.phoneNumber1,
        aadharNo: studentData.aadharNo,
        category: studentData.category,
        nameofInstution: studentData.nameofInstution,
        courseName: studentData.courseName,
        requestType: studentData.requestType,
        withReferenceOf: studentData.withReferenceOf,
        reference:
          studentData.reference.length > 0
            ? studentData.reference
            : [{ friendName: "", friendPhoneNumber: "" }],
        qualifyingDetails: {
          sscSchoolName: studentData.qualifyingDetails.sscSchoolName,
          sscPassingYear:
            studentData.qualifyingDetails.sscPassingYear.toString(),
          sscPercentage: studentData.qualifyingDetails.sscPercentage.toString(),
          hscSchoolName: studentData.qualifyingDetails.hscSchoolName,
          hscPassingYear:
            studentData.qualifyingDetails.hscPassingYear.toString(),
          hscPercentage: studentData.qualifyingDetails.hscPercentage.toString(),
          EAPCETHallTicketNo: studentData.qualifyingDetails.EAPCETHallTicketNo,
          EAPCETRank: studentData.qualifyingDetails.EAPCETRank.toString(),
        },
      });
      console.log("Fetched student details:", response.data);
    } catch (error) {
      console.error("Error fetching student details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (studentId) {
      fetchStudentDetails();
    }
  }, [studentId]);
  const handleGetEmployees = async () => {
    try {
      setLoading(true);
      const response = await api.getAllEmployees();
      console.log("API Response:", response.data);
      setEmployees(response.data);
    } catch (error) {
      console.error("Error fetching employees:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    handleGetEmployees();
  }, []);

  useEffect(() => {}, [employees]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await axios.put(
        `https://ysr-backend-1.onrender.com/api/v1/user/admin/update-student/${studentId}`,
        formData
      );
      if (res.data.success) {
        setMessage("Form submitted successfully!");
      } else {
        setMessage(
          "Error submitting form: " + (res.data.message || res.statusText)
        );
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setMessage("Error submitting form");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Container>
      <FormWrapper onSubmit={handleSubmit}>
        <FormControl>
          <Label>Date:</Label>
          <Input
            type="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
          />
          <Label>Name of Applicant:</Label>
          <Input
            type="text"
            name="nameOfApplicant"
            value={formData.nameOfApplicant}
            onChange={handleChange}
          />

          <Label>Father Name:</Label>
          <Input
            type="text"
            name="fatherName"
            value={formData.fatherName}
            onChange={handleChange}
          />

          <Label>Date of Birth:</Label>
          <Input
            type="date"
            name="dateOfBirth"
            value={formData.dateOfBirth}
            onChange={handleChange}
          />

          <Label>Address of Communication:</Label>
          <Input
            type="text"
            name="addressOfCommunication"
            value={formData.addressOfCommunication}
            onChange={handleChange}
          />

          <Label>Phone Number:</Label>
          <Input
            type="text"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
          />

          <Label>Alternate Phone Number:</Label>
          <Input
            type="text"
            name="phoneNumber1"
            value={formData.phoneNumber1}
            onChange={handleChange}
          />

          <Label>Aadhar No:</Label>
          <Input
            type="text"
            name="aadharNo"
            value={formData.aadharNo}
            onChange={handleChange}
          />

          <Label>Category:</Label>
          <Select
            name="category"
            value={formData.category}
            onChange={handleChange}
          >
            <option value="">Select Category</option>
            <option value="OC">OC</option>
            <option value="OC/EWS">OC/EWS</option>
            <option value="BCA">BCA</option>
            <option value="BCB">BCB</option>
            <option value="BCC">BCC</option>
            <option value="BCD">BCD</option>
            <option value="SC">SC</option>
            <option value="ST">ST</option>
          </Select>

          <Label>Name of Institution:</Label>
          <Input
            type="text"
            name="nameofInstution"
            value={formData.nameofInstution}
            onChange={handleChange}
          />

          <Label>Request Type:</Label>
          <Input
            type="text"
            name="requestType"
            value={formData.requestType}
            onChange={handleChange}
          />
          <div>
            <div>
              <Label>
                Select Course: <span style={{ color: "red" }}>*</span>
              </Label>
              <CheckboxGroup>
                <LabelA>
                  <Input
                    type="checkbox"
                    name="courseLevel"
                    value="EEE"
                    checked={formData.courseName.includes("EEE")}
                    onChange={handleCheckboxChange}
                  />
                  EEE
                </LabelA>
                <LabelA>
                  <Input
                    type="checkbox"
                    name="courseLevel"
                    value="ECE"
                    checked={formData.courseName.includes("ECE")}
                    onChange={handleCheckboxChange}
                  />
                  ECE
                </LabelA>
                <LabelA>
                  <Input
                    type="checkbox"
                    name="courseLevel"
                    value="CSE"
                    checked={formData.courseName.includes("CSE")}
                    onChange={handleCheckboxChange}
                  />
                  CSE
                </LabelA>
                <LabelA>
                  <Input
                    type="checkbox"
                    name="courseLevel"
                    value="MECH"
                    checked={formData.courseName.includes("MECH")}
                    onChange={handleCheckboxChange}
                  />
                  MECH
                </LabelA>
                <LabelA>
                  <Input
                    type="checkbox"
                    name="courseLevel"
                    value="CIVIL"
                    checked={formData.courseName.includes("CIVIL")}
                    onChange={handleCheckboxChange}
                  />
                  CIVIL
                </LabelA>
                <LabelA>
                  <Input
                    type="checkbox"
                    name="courseLevel"
                    value="IT"
                    checked={formData.courseName.includes("IT")}
                    onChange={handleCheckboxChange}
                  />
                  IT
                </LabelA>
              </CheckboxGroup>
            </div>
          </div>

          <div>
            <Label>
              With Reference Of: <span style={{ color: "red" }}>*</span>
            </Label>
            <Select
              name="withReferenceOf"
              value={formData.withReferenceOf}
              onChange={handleChange}
            >
              <option value="">Select With Reference Of</option>
              {employees &&
                employees.map((employee) => (
                  <option
                    key={`${employee.name} (${employee.phoneNumber})`}
                    value={`${employee.name} (${employee.phoneNumber})`}
                  >
                    {`${employee.name} (${employee.phoneNumber})`}
                  </option>
                ))}
            </Select>
          </div>

          <Label>Friend Name:</Label>
          <Input
            type="text"
            name="friendName"
            value={formData.reference[0].friendName}
            onChange={handleReferenceChange}
          />

          <Label>Friend Phone Number:</Label>
          <Input
            type="text"
            name="friendPhoneNumber"
            value={formData.reference[0].friendPhoneNumber}
            onChange={handleReferenceChange}
          />

          <h3>Qualifying Details</h3>

          <Label>SSC School Name:</Label>
          <Input
            type="text"
            name="sscSchoolName"
            value={formData.qualifyingDetails.sscSchoolName}
            onChange={handleNestedChange}
          />

          <Label>SSC Passing Year:</Label>
          <Input
            type="number"
            name="sscPassingYear"
            value={formData.qualifyingDetails.sscPassingYear}
            onChange={handleNestedChange}
          />

          <Label>SSC Percentage:</Label>
          <Input
            type="number"
            name="sscPercentage"
            value={formData.qualifyingDetails.sscPercentage}
            onChange={handleNestedChange}
          />

          <Label>HSC School Name:</Label>
          <Input
            type="text"
            name="hscSchoolName"
            value={formData.qualifyingDetails.hscSchoolName}
            onChange={handleNestedChange}
          />

          <Label>HSC Passing Year:</Label>
          <Input
            type="number"
            name="hscPassingYear"
            value={formData.qualifyingDetails.hscPassingYear}
            onChange={handleNestedChange}
          />

          <Label>HSC Percentage:</Label>
          <Input
            type="number"
            name="hscPercentage"
            value={formData.qualifyingDetails.hscPercentage}
            onChange={handleNestedChange}
          />

          <Label>EAPCET Hall Ticket No:</Label>
          <Input
            type="text"
            name="EAPCETHallTicketNo"
            value={formData.qualifyingDetails.EAPCETHallTicketNo}
            onChange={handleNestedChange}
          />

          <Label>EAPCET Rank:</Label>
          <Input
            type="number"
            name="EAPCETRank"
            value={formData.qualifyingDetails.EAPCETRank}
            onChange={handleNestedChange}
          />
          <Button type="submit">Submit</Button>
          {message && <p>{message}</p>}
        </FormControl>
      </FormWrapper>
    </Container>
  );
};

export default EapcetUpdateForm;
