import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./Output.module.css";
import img from "../Images/YSR logo.jpeg";
import api from "../../api";

function Output() {
  const { studentId } = useParams();
  const [studentDetails, setStudentDetails] = useState(null);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStudentDetails = async () => {
      try {
        const res = await api.getStudentsDetailsById(studentId);
        if (res.success === true) {
          setStudentDetails(res.data);
        } else {
          setMessage(
            "Error Fetching Student Details: " +
              (res.data.message || res.statusText)
          );
        }
      } catch (error) {
        if (error.response) {
          console.error("Error response data:", error.response.data);
          console.error("Error response status:", error.response.status);
          console.error("Error response headers:", error.response.headers);
        } else if (error.request) {
          console.error("Error request:", error.request);
        } else {
          console.error("Error message:", error.message);
        }
        console.error("Error config:", error.config);
        setMessage("An error occurred while fetching student details.");
      } finally {
        setLoading(false);
      }
    };

    fetchStudentDetails();
  }, [studentId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (message) {
    return <div>{message}</div>;
  }

  if (!studentDetails) {
    return <div>No student details found.</div>;
  }

  return (
    <div className={styles.body}>
      <div className={styles.content}>
        <header className={styles.header}>
          <div className={styles.logo}>
            <img src={img} alt="" className={styles.img} />
          </div>
          <div className={styles.heading}>
            <h1 className={styles.main}>YSR EDUCATIONAL SERVICES</h1>
            <h6 className={styles.subMain}>
              "enlightening the values of education"
            </h6>
            <div>
              <p className={styles.addresses}>
                {" "}
                <span className={styles.address}>
                  Flat No. 6-36/4, Room no.4, 1<sup>st</sup> floor, opp.
                  kukatpally Bus Depot, Moosapet,{" "}
                </span>
                <span className={styles.address2}>
                  {" "}
                  Hyderabad-500 018,T.S., India.
                  <span style={{ padding: "20px" }}>
                    Cell:9959693939,9133423939
                  </span>
                </span>
              </p>
            </div>
          </div>
        </header>
        <hr className={styles.line} />
        <h1 className={styles.subHead}>preliminary application form</h1>
        <div className={styles.date}>
          <label className={styles.label}>Date:</label>
          <span className={styles.dateans}>{studentDetails.date}</span>
        </div>
        <table className={styles.tableOne}>
          <tr>
            <th>
              1. Name of the applicant in full form
              <br />
              &nbsp;&nbsp;&nbsp; (In block Letters as per SSC)
            </th>
            <td className={styles.semiColon}>:</td>
            <td>
              <div name="applicantname" className={styles.inputField}>
                {studentDetails.nameOfApplicant}
              </div>
            </td>
          </tr>
          <tr>
            <th>2. Father Name</th>
            <td className={styles.semiColon}>:</td>
            <td>
              <div className={styles.inputField}>
                {studentDetails.fatherName}
              </div>
            </td>
          </tr>
          <tr>
            <th>3. Date of Birth</th>
            <td className={styles.semiColon}>:</td>
            <td>
              <div className={styles.inputField}>
                {studentDetails.dateOfBirth}
              </div>
            </td>
          </tr>
          <tr>
            <th>4. Address for Communication</th>
            <td className={styles.semiColon}>:</td>
            <td>
              <div className={styles.inputField}>
                {studentDetails.addressOfCommunication}
              </div>
            </td>
          </tr>
          <tr>
            <th></th>
            <td className={styles.semiColon}>:</td>
            <td>
              <input
                type="text"
                name="address"
                className={styles.inputField}
              ></input>
            </td>
          </tr>
          <tr>
            <th>5. Tel.No/Mobile No.</th>
            <td className={styles.semiColon}>:</td>
            <td>
              <div className={styles.inputField}>
                {studentDetails.phoneNumber},{studentDetails.phoneNumber}
              </div>
            </td>
          </tr>
          <tr>
            <th>6. Aadhar no.</th>
            <td className={styles.semiColon}>:</td>
            <td>
              <div className={styles.inputField}>{studentDetails.aadharNo}</div>
            </td>
          </tr>
          <tr>
            <th>
              7. Category : management Quota/NRI
              <br />
              &nbsp;&nbsp;&nbsp; Foreign Student Quota(if Applicable)
            </th>
            <td className={styles.semiColon}>:</td>
            <td>
              <input
                type="text"
                name="category"
                className={styles.inputField}
              ></input>
            </td>
          </tr>
          <tr>
            <th>8. OC/BC(A,B,C,D),SC/ST</th>
            <td className={styles.semiColon}>:</td>
            <td>
              <div className={styles.inputField}>{studentDetails.category}</div>
            </td>
          </tr>
        </table>
        <h6 className={styles.degree}>
          {" "}
          9. Details of Qualifying Examination (10+2) or Degree
        </h6>
        <table className={styles.tableTwo} cellSpacing={0}>
          <tr>
            <th style={{ width: "100px" }}>Course</th>
            <th>
              Board/
              <br />
              University
            </th>
            <th>
              Month & Year <br />
              of passing
            </th>
            <th>
              Marks <br />
              Obtained
            </th>
            <th>
              Percentage
              <br /> of Marks
            </th>
            <th>
              CEEP/EAPCET/ECET
              <br />
              /ICET/PGCET.HNo.
            </th>
            <th>
              CEEP/EAPCET/ECET
              <br />
              ICET/PGCET-Rank
            </th>
          </tr>
          <tr>
            <th>SSC</th>
            <td>
              <div>{studentDetails.qualifyingDetails.sscSchoolName}</div>
            </td>
            <td>
              <div>{studentDetails.qualifyingDetails.sscPassingYear}</div>
            </td>
            <td>
              <div></div>
            </td>
            <td>
              <div>{studentDetails.qualifyingDetails.sscPercentage}</div>
            </td>
            <td>
              <div>{studentDetails.qualifyingDetails.EAPCETHallTicketNo}</div>
            </td>
            <td>
              <div>{studentDetails.qualifyingDetails.EAPCETRank}</div>
            </td>
          </tr>
          <tr>
            <th>DIPLOMA</th>
            <td>
              <input type="text" name="diplomaBoard" />
            </td>
            <td>
              <input type="text" name="diplomaPassingYear" />
            </td>
            <td>
              <input type="text" name="diplomaMarks" />
            </td>
            <td>
              <input type="text" name="diplomaPercentage" />
            </td>
            <td>
              <input type="text" name="diplomaEntranceExamHallTicket" />
            </td>
            <td>
              <input type="text" name="diplomaEntranceExamRank" />
            </td>
          </tr>
          <tr>
            <th>INTER</th>
            <td>
              <div>{studentDetails.qualifyingDetails.hscSchoolName}</div>
            </td>
            <td>
              <div>{studentDetails.qualifyingDetails.hscPassingYear}</div>
            </td>
            <td>
              <div></div>
            </td>
            <td>
              <div>{studentDetails.qualifyingDetails.hscPercentage}</div>
            </td>
            <td>
              <div>{studentDetails.qualifyingDetails.EAPCETHallTicketNo}</div>
            </td>
            <td>
              <div>{studentDetails.qualifyingDetails.EAPCETRank}</div>
            </td>
          </tr>
          <tr>
            <th>DEGREE</th>
            <td>
              <input type="text" name="degreeCourse" />
            </td>
            <td>
              <input type="text" name="degreePassingYear" />
            </td>
            <td>
              <input type="text" name="degreeMarks" />
            </td>
            <td>
              <input type="text" name="degreePercentage" />
            </td>
            <td>
              <input type="text" name="degreeEntranceExamHallTicket" />
            </td>
            <td>
              <input type="text" name="degreeEntranceExamRank" />
            </td>
          </tr>
        </table>
        <h6 className={styles.institute}>
          10. Name of the institutions in which admission is required
          <div className={styles.inputThree}>
            {studentDetails.nameofInstution}
          </div>
        </h6>
        <h6 className={styles.course}>
          11. Name of the course in which admission is required
        </h6>
        <p style={{ marginTop: "-25px" }}>
          <p className={styles.check}>
            A) POLYTECHNIC :
            <label
              className={styles.label}
              for="polyeee"
              style={{ marginLeft: "1px" }}
            >
              EEE
            </label>
            <input
              type="checkbox"
              id="polyeee"
              name="poly"
              className={styles.checkInput}
            />
            <label className={styles.label} for="polyece">
              ECE
            </label>
            <input
              type="checkbox"
              id="polyece"
              name="poly"
              className={styles.checkInput}
            />
            <label className={styles.label} for="polymech">
              MECH
            </label>
            <input
              type="checkbox"
              id="polymech"
              name="poly"
              className={styles.checkInput}
            />
            <label className={styles.label} for="polycivil">
              CIVIL
            </label>
            <input
              type="checkbox"
              id="polycivil"
              name="poly"
              className={styles.checkInput}
            />
          </p>
          <br />
          <p className={styles.check}>
            B) B.TECH :{" "}
            <label
              className={styles.label}
              for="btecheee"
              style={{ marginLeft: "2px" }}
            >
              EEE
            </label>
            <input
              type="checkbox"
              id="btecheee"
              name="btech"
              value="eee"
              className={styles.checkInput}
            />
            <label className={styles.label} for="btechece">
              ECE
            </label>
            <input
              type="checkbox"
              id="btechece"
              name="btech"
              value="ece"
              className={styles.checkInput}
            />
            <label className={styles.label} for="btechcse">
              CSE
            </label>
            <input
              type="checkbox"
              id="btechcse"
              name="btech"
              value="cse"
              className={styles.checkInput}
            />
            <label className={styles.label} for="btechmech">
              MECH
            </label>
            <input
              type="checkbox"
              id="btechmech"
              name="btech"
              value="mech"
              className={styles.checkInput}
            />
            <label className={styles.label} for="btechcivil">
              CIVIL
            </label>
            <input
              type="checkbox"
              id="btechcivil"
              name="btech"
              value="civil"
              className={styles.checkInput}
            />
            <label className={styles.label} for="btechit">
              IT
            </label>
            <input
              type="checkbox"
              id="btechit"
              name="btech"
              value="it"
              className={styles.checkInput}
            />
          </p>
          <br />
          <p className={styles.check}>
            C){" "}
            <label
              className={styles.label}
              for="bpharm"
              style={{ marginLeft: "2px" }}
            >
              B.PHARMACY
            </label>
            <input
              type="checkbox"
              id="bpharm"
              name="bpharm"
              value="bpharm"
              className={styles.checkInput}
            />
          </p>
          <br />
          <p className={styles.check}>
            D){" "}
            <label
              className={styles.label}
              for="mbacheck"
              style={{ marginLeft: "2px" }}
            >
              MBA
            </label>
            <input
              type="checkbox"
              id="mbacheck"
              name="mba"
              value="mba"
              className={styles.checkInput}
            />
          </p>
          <br />
          <p className={styles.check}>
            E) M.TECH :
            <label
              className={styles.label}
              for="civilcheck"
              style={{ marginLeft: "2px" }}
            >
              CIVIL (SE,TE)
            </label>
            <input
              type="checkbox"
              id="civilcheck"
              name="mtech"
              value="civil"
              className={styles.checkInput}
            />
            <label className={styles.label} for="ececheck">
              ECE(VLSI,ES,VLSI&ES)
            </label>
            <input
              type="checkbox"
              id="ececheck"
              name="mtech"
              value="ece"
              className={styles.checkInput}
            />
            <label className={styles.label} for="eeecheck">
              EEE(PE/EPS)
            </label>
            <input
              type="checkbox"
              id="eeecheck"
              name="mtech"
              value="eee"
              className={styles.checkInput}
            />
          </p>
          <br />
          <p className={styles.check}>
            <label className={styles.label} for="mechcheck">
              MECH(CAD/CAM/AMS/thermal, MD)
            </label>
            <input
              type="checkbox"
              id="mechcheck"
              name="mtech"
              value="mech"
              className={styles.checkInput}
            />
            <label className={styles.label} for="cseCheck">
              CSE(CSE/SE)
            </label>
            <input
              type="checkbox"
              id="cseCheck"
              name="mtech"
              value="cse"
              className={styles.checkInput}
            />
          </p>
          <br />
          <p className={styles.check}>
            F) M. PHARMACY :
            <label
              className={styles.label}
              for="pharmacheck1"
              style={{ marginLeft: "3px" }}
            >
              PHARMACEUTICS
            </label>
            <input
              type="checkbox"
              id="pharmacheck1"
              name="pharma"
              value="pharmaceutics"
              className={styles.checkInput}
            />
            <label className={styles.label} for="pmracheck2">
              PMRA
            </label>
            <input
              type="checkbox"
              id="pmracheck2"
              name="pharma"
              value="pmra"
              className={styles.checkInput}
            />
            <label className={styles.label} for="paqacheck3">
              PAQA
            </label>
            <input
              type="checkbox"
              id="paqacheck3"
              name="pharma"
              value="paqa"
              className={styles.checkInput}
            />
            <label className={styles.label} for="ipcheck4">
              IP
            </label>
            <input
              type="checkbox"
              id="ipcheck4"
              name="pharma"
              value="ip"
              className={styles.checkInput}
            />
            <label className={styles.label} for="pccheck5">
              PC
            </label>
            <input
              type="checkbox"
              id="pccheck5"
              name="pharma"
              value="pc"
              className={styles.checkInput}
            />
          </p>
          <br />
          <p className={styles.check}>
            <label
              className={styles.label}
              for="mbbscheck"
              style={{ marginLeft: "-2px" }}
            >
              G) MBBS
            </label>
            <input
              type="checkbox"
              id="mbbscheck"
              name="mbbs"
              value="mbbs"
              className={styles.checkInput}
            />
            <label className={styles.label} for="bdscheck">
              H) BDS
            </label>
            <input
              type="checkbox"
              id="bdscheck"
              name="bds"
              value="bds"
              className={styles.checkInput}
            />
            <label className={styles.label} for="agbsccheck">
              I) Ag.BSC
            </label>
            <input
              type="checkbox"
              id="agbsccheck"
              name="agbsc"
              value="agbsc"
              className={styles.checkInput}
            />
          </p>
        </p>
        <h6 className={styles.payment}>
          12. Structure of payments: at a time/Year wise:
          <input className={styles.inputTwo}></input>
        </h6>
        <h6 className={styles.refer13}>
          13. with Reference of:{" "}
          <span className={styles.inputTwofor}>
            {studentDetails.withReferenceOf}
          </span>
        </h6>
        <h6 className={styles.refer13}>
          14. Reference:Mention the name of two friends known to you with phone
          Numbers's
        </h6>
        <div className={styles.sign}>
          <div className={styles.count}>
            <span>
              1){" "}
              <input
                type="text"
                name="ref1"
                className={styles.inputTwo}
              ></input>
            </span>
            <span>
              2){" "}
              <input
                type="text"
                name="ref2"
                className={styles.inputTwo}
              ></input>
            </span>
          </div>
          <br />
          <br />
          <br />
          <div className={styles.inSign}>
            <div>signature of the parent</div>
            <div>signature of the student</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Output;
