import React, { useState, useEffect } from "react";
import CustomLoadingButton from "../../helper/CustomLoadingButton";
import styled from "styled-components";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CloseIcon from "@mui/icons-material/Close";
import api from "../../api";

const Container = styled.div`
  max-width: 100%;
  margin: auto;
`;

const EmployeesListPage = () => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [deleting, setDeleting] = useState(null);
  const [updating, setUpdating] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [confirmUpdateDialog, setConfirmUpdateDialog] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState({
    id: "",
    name: "",
    phoneNumber: "",
  });

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await api.getAllEmployeesList();
        if (!response.success) {
          throw new Error("Failed to fetch employees");
        }
        setEmployees(response.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchEmployees();
  }, []);

  const handleDelete = async () => {
    setDeleting(selectedEmployee.id);
    try {
      const response = await api.removeEmployee(selectedEmployee.id);
      if (!response.success) {
        throw new Error(response.message || "Failed to delete employee");
      }
      setEmployees((prevEmployees) =>
        prevEmployees.filter((employee) => employee.id !== selectedEmployee.id)
      );
      setAlertMessage(response.message);
      setOpenDeleteDialog(false);
    } catch (error) {
      setAlertMessage(error.message);
    } finally {
      setDeleting(null);
    }
  };

  const handleOpenUpdateDialog = (employee) => {
    setSelectedEmployee(employee);
    setOpenUpdateDialog(true);
  };

  const handleCloseUpdateDialog = () => {
    setOpenUpdateDialog(false);
  };

  const handleUpdate = async () => {
    setUpdating(selectedEmployee.id);
    try {
      const { id, name, phoneNumber } = selectedEmployee;
      const response = await api.updateEmployee(id, name, phoneNumber);
      if (!response.success) {
        throw new Error(response.message || "Failed to update employee");
      }
      setEmployees((prevEmployees) =>
        prevEmployees.map((employee) =>
          employee.id === selectedEmployee.id ? selectedEmployee : employee
        )
      );
      setAlertMessage(response.message);
      setConfirmUpdateDialog(false);
      setOpenUpdateDialog(false);
    } catch (error) {
      setAlertMessage(error.message);
    } finally {
      setUpdating(null);
    }
  };

  const handleOpenDeleteDialog = (employee) => {
    setSelectedEmployee(employee);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  const handleConfirmUpdate = () => {
    setConfirmUpdateDialog(true);
  };

  const handleCloseConfirmUpdateDialog = () => {
    setConfirmUpdateDialog(false);
  };

  if (loading) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Alert severity="error">Error: {error}</Alert>
      </Container>
    );
  }

  return (
    <div className="MainFocus">
      <Container>
        <Typography variant="h4" gutterBottom>
          Employees List
        </Typography>
        {alertMessage && <Alert severity="info">{alertMessage}</Alert>}
        <TableContainer component={Paper} sx={{ backgroundColor: "#f5f5f5" }}>
          <Table>
            <TableHead sx={{ backgroundColor: "#0D83AD" }}>
              <TableRow>
                <TableCell sx={{ color: "#ffffff" }}>ID</TableCell>
                <TableCell sx={{ color: "#ffffff" }}>Name</TableCell>
                <TableCell sx={{ color: "#ffffff" }}>Phone Number</TableCell>
                <TableCell sx={{ color: "#ffffff", textAlign: "center" }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {employees.map((employee) => (
                <TableRow key={employee.id}>
                  <TableCell>{employee.id}</TableCell>
                  <TableCell>{employee.name}</TableCell>
                  <TableCell>{employee.phoneNumber}</TableCell>
                  <TableCell>
                    <Box display="flex" justifyContent="center" gap={1}>
                      <IconButton
                        color="primary"
                        onClick={() => handleOpenUpdateDialog(employee)}
                      >
                        {" "}
                        <BorderColorIcon />
                      </IconButton>
                      <IconButton
                        color="secondary"
                        loading={deleting === employee.id}
                        onClick={() => handleOpenDeleteDialog(employee)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Delete Confirmation Dialog */}
        <Dialog
          open={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
          aria-labelledby="delete-dialog-title"
          aria-describedby="delete-dialog-description"
        >
          <DialogTitle id="delete-dialog-title">Confirm Delete</DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              Are you sure you want to delete this employee?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="primary">
              Cancel
            </Button>
            <CustomLoadingButton
              loading={deleting === selectedEmployee.id}
              onClick={handleDelete}
              color="secondary"
            >
              Delete
            </CustomLoadingButton>
          </DialogActions>
        </Dialog>

        {/* Update Confirmation Dialog */}
        <Dialog open={openUpdateDialog} onClose={handleCloseUpdateDialog}>
          <DialogTitle>
            Update Employee
            <IconButton
              aria-label="close"
              onClick={handleCloseUpdateDialog}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Name"
              type="text"
              fullWidth
              value={selectedEmployee.name}
              onChange={(e) =>
                setSelectedEmployee({
                  ...selectedEmployee,
                  name: e.target.value,
                })
              }
            />
            <TextField
              margin="dense"
              label="Phone Number"
              type="text"
              fullWidth
              inputProps={{ maxLength: 10 }}
              value={selectedEmployee.phoneNumber}
              onChange={(e) =>
                setSelectedEmployee({
                  ...selectedEmployee,
                  phoneNumber: e.target.value,
                })
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseUpdateDialog}>Cancel</Button>
            <Button onClick={handleConfirmUpdate} variant="outlined">
              Update
            </Button>
          </DialogActions>
        </Dialog>

        {/* Confirm Update Dialog */}
        <Dialog
          open={confirmUpdateDialog}
          onClose={handleCloseConfirmUpdateDialog}
          aria-labelledby="confirm-update-dialog-title"
          aria-describedby="confirm-update-dialog-description"
        >
          <DialogTitle id="confirm-update-dialog-title">
            Confirm Update
          </DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              Are you sure you want to update this employee?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseConfirmUpdateDialog} color="primary">
              Cancel
            </Button>
            <CustomLoadingButton
              loading={updating === selectedEmployee.id}
              onClick={handleUpdate}
              variant="outlined"
            >
              Confirm
            </CustomLoadingButton>
          </DialogActions>
        </Dialog>
      </Container>
    </div>
  );
};

export default EmployeesListPage;
