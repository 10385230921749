import "./App.css";
import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import MainLayout from "./Components/Admin/Mainlayout";
import AdminHome from "./Components/Admin/AdminHome";
import AddAdmin from "./Components/Admin/AddAdmin";
import EmployeesListPage from "./Components/Admin/EmployeesListPage";
import StudentDetailsPage from "./Components/Admin/StudentDetailsPage";
import StudentList from "./Components/Admin/StudentList";
import Output from "./Components/Output/Output";
import AddEmployeeForm from "./Components/Admin/AddEmployeeForm";
import EapcetStudentsListing from "./Components/EAPCET/EapcetStudentsListing";
import EmployeeSuccessResponse from "./Components/SuccessResponse/EmployeeSuccessResponse";
import AddEapcetDocuments from "./Components/Admin/AddEapcetDocuments";
import StudentDocumentsDetails from "./Components/EAPCET/StudentDocumentsDetails ";
import EapcetDet from "./Components/ICET/EapcetDet";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<AdminHome />} />
          <Route path="/add-admin" element={<AddAdmin />} />
          <Route path="/add-employee" element={<AddEmployeeForm />} />
          <Route
            path="/employee-response"
            element={<EmployeeSuccessResponse />}
          />
          <Route path="/employees-list" element={<EmployeesListPage />} />
          <Route path="/student-details" element={<StudentDetailsPage />} />
          <Route
            path="/add-certificate-details/:id"
            element={<AddEapcetDocuments />}
          />
          <Route
            path="/student-documents-details/:id"
            element={<StudentDocumentsDetails />}
          />
          <Route
            path="/eapcet-students-listing"
            element={<EapcetStudentsListing />}
          />
          <Route path="/icet-students-listing" element={<EapcetDet />} />
          <Route path="/allType-students-list" element={<StudentList />} />
        </Route>
        <Route
          path={"/get-student-details-by-id/:studentId"}
          element={<Output />}
        />
        <Route path="*" element={<h1>404 Error, Page Not Found</h1>} />
      </Routes>
    </Router>
  );
};

export default App;
