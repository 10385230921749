import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  Button,
  CssBaseline,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import SchlLogo from "../Images/YSR logo.jpeg";
import Walkins from "./Walkins";
import Confirmations from "./Confirmations";

const drawerWidth = 240;

const AdminHome = () => {
  const navigate = useNavigate();

  const handleAddAdmin = () => {
    navigate("/add-admin");
  };
  const handleAddEmployee = () => {
    navigate("/add-employee");
  };
  const handleEmployeeList = () => {
    navigate("/employees-list");
  };

  const handleFindStudent = (examType) => {
    console.log(`Find Student (${examType}) clicked`);
    navigate(`/${examType.toLowerCase()}-students-listing`);
  };

  const handleListExams = () => {
    console.log("List All Exams clicked");
    navigate("/allType-students-list");
  };

  const handleSeeStudentDetails = () => {
    console.log("See Student Details clicked");
    navigate("/student-details");
  };

  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "#1976d2",
          }}
        >
          <Toolbar>
            <IconButton>
              <img
                src={SchlLogo}
                style={{
                  maxWidth: "50px",
                  maxWidth: "50px",
                  borderRadius: "50%",
                }}
              />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              YSR Educational Services
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
              backgroundColor: "#282c34",
              color: "#ffffff",
              zIndex: "1000",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Toolbar />
          <List>
            <ListItem button onClick={handleAddAdmin}>
              <ListItemText primary="Add Admin" />
            </ListItem>
            <ListItem button onClick={handleAddEmployee}>
              <ListItemText primary="Add Employee" />
            </ListItem>
            <ListItem button onClick={handleEmployeeList}>
              <ListItemText primary="Employees List" />
            </ListItem>
            <ListItem button onClick={handleSeeStudentDetails}>
              <ListItemText primary="See Student Details" />
            </ListItem>
            <ListItem button onClick={() => handleFindStudent("EAPCET")}>
              <ListItemText primary="Find Student (EAPCET)" />
            </ListItem>
            <ListItem button onClick={() => handleFindStudent("ECET")}>
              <ListItemText primary="Find Student (ECET)" />
            </ListItem>
            <ListItem button onClick={() => handleFindStudent("ICET")}>
              <ListItemText primary="Find Student (ICET)" />
            </ListItem>
            <ListItem button onClick={() => handleFindStudent("PGECET")}>
              <ListItemText primary="Find Student (PGECET)" />
            </ListItem>
            <ListItem button onClick={() => handleFindStudent("Management")}>
              <ListItemText primary="Find Student (Management)" />
            </ListItem>
            <ListItem button onClick={handleListExams}>
              <ListItemText primary="List All Exams" />
            </ListItem>
          </List>
        </Drawer>
      </Box>

      <Grid container spacing={3}>
        {" "}
        <Grid item xs={12} md={6}>
          <Walkins />
        </Grid>
        <Grid item xs={12} md={6}>
          <Button>
            <Confirmations />{" "}
          </Button>
        </Grid>
      </Grid>
      <Toolbar />
    </div>
  );
};

export default AdminHome;
